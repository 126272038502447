//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import formMixin from '~/mixins/formMixin'
// import FormGroup from '~/components/form/FormGroup'

export default {
  name: 'SigninForm',
  components: {
    // FormGroup
  },
  mixins: [
    formMixin
  ],
  data () {
    return {
      redirectAfterSuccess: true
    }
  },
  computed: {
    signupUrl () {
      return this.localePath('/en/sign-up')
    },
    forgotPasswordUrl () {
      return this.localePath('/en/forgot-password')
    },
    locale () {
      return this.$store.getters.locale
    },
    formFields () {
      return [
        {
          name: 'email',
          validation: 'required|email',
          label: this.$t('signin.form.email.label'),
          helpText: this.$te('signin.form.email.help')
            ? this.$t('signin.form.email.help')
            : '',
          options: {
            type: 'email',
            placeholder: '',
            required: true,
            // specific
            class: 'col-md-12'
          }
        },
        {
          name: 'password',
          validation: 'required|max:255',
          label: this.$t('signin.form.password.label'),
          helpText: this.$te('signin.form.password.help')
            ? this.$t('signin.form.password.help')
            : '',
          options: {
            type: 'password',
            placeholder: '',
            required: true,
            // specific
            class: 'col-md-12'
          }

        }
      ]
    }
  },
  mounted () {
    if (this.$route.query['password-reset']) {
      this.showMessage(this.$t('signin.passwordResetMessage'), this.$t('signin.passwordResetTitle'), 'success')
    }
    if (this.$route.query['verification-failed']) {
      this.showMessage(this.$t('signin.failedVerificationMessage'), this.$t('signin.failedVerificationTitle'), 'danger')
    }
  },
  methods: {
    async $_sendRequest (url, data) {
      // Options
      // @TODO create a configurable request options.
      let result
      try {
        result = await this.$auth.loginWith('laravelSanctum', {
          data
        })
      } catch (e) {
        if (!e.response) {
          return
        }

        if (e.response.status === 422) {
          // handle validation errors
          // const serverData = e.response.data
          // if (serverData.errors) {
          //   this.$refs.observer.setErrors(serverData.errors)
          // }
          this.showError(this.$t('form.validationErrorMessage'), this.$t('form.errorTitle'), 'danger')
        }
        if (e.response.code === 500) {
          // handel server error
          this.showServerError(this.$t('signin.form.errorTitle'))
        }
        throw e
      }

      return result
    }

    // showValidationError (mess) {
    //   this.showError(this.$t('form.valErrorMessage'), 'danger')
    // }
  }
}
